import '../App.css';
import '../styles/ExplorePage.css'
import React, {useState, useRef, useEffect} from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import Dropdown from '../components/Dropdown.js';
import {fetchCollection, bedsToString} from '../utils/UtilFunctions.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import Carousel from '../components/Carousel.js';
import mapboxgl from 'mapbox-gl';
import MapboxCircle from 'mapbox-gl-circle';
import SearchModal from './SearchModal.js';
// Firebase
import {initializeApp} from "firebase/app";
import {getFirestore, collection, doc, getDoc} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5nZWxvcmFtb3MiLCJhIjoiY2xlN3NtNGxoMDhmNTNxb3hmbGc3d21sYSJ9.34bfp7X8jJKm-upwVp4hQQ';

function SearchResultsScreen(props) {
  const {state} = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const [user, setUser] = useState(null);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  const [experiences, setExperiences] = useState([]);
  const [stays, setStays] = useState([]);
  const [selectedListingType, setSelectedListingType] = useState('All');
  const [searchModalVisible, setSearchModalVisible] = useState(false);

  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    // amplitude.track('view_explore_page', {
    //   initial_destination: state !== null ? state.destination : 'Thailand',
    //
    // });
    fetchCollection('stays', (results) => setStays(results.map(result => ({ ...result, carousel_index: 0 }))));
    fetchCollection('experiences', (results) => setExperiences(results));
  }, []);

  useEffect(() => {
    if (mapContainerRef.current && !map) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [
          100, 15
        ],
        zoom: 8
      });

      setMap(map);
    }
  }, [mapContainerRef, map]);

  const setCarouselIndex = (listing, index) => {
    console.log('new index', index);
    const newStays = [...stays];
    const indexToUpdate = newStays.map(stay => stay.id).indexOf(listing.id);
    console.log('stay index', indexToUpdate);
    newStays[indexToUpdate].carousel_index = index;
    console.log(newStays[indexToUpdate]);
    setStays(newStays);
  }

  const isMember = user !== null && user.stripe_subscription_status === 'active';

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="page-container white-fill">
      {
        searchModalVisible
          ? <div className="dim-view"/>
          : null
      }
      <div style={{ padding: 0 }} className={'offwhite-fill notify-modal' + (
        searchModalVisible
        ? ' slide-up'
        : ' slide-out')}>
        <SearchModal setSearchModalVisible={setSearchModalVisible}/>
      </div>
      <div style={{ paddingTop: 64, paddingBottom: 48, width: isMobile ? '90%' : '94%' }}>
        {!isMobile && <div ref={mapContainerRef} style={{ position: 'fixed', width: '35%', height: '90vh', right: 0, bottom: 0 }} />}
        <div style={{ paddingTop: 24, textAlign: 'left', width: isMobile ? '100%' : '64%' }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div onClick={() => setSearchModalVisible(true)} className="offwhite-fill" style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4, position: 'relative', width: isMobile ? '100%' : '70%', height: 40, borderRadius: 10, border: '1px solid #EBEBEB' }}>
              <div style={{ paddingLeft: 12, boxSizing: 'border-box', display: 'flex', flexDirection: 'row', alignItems: 'center', width: isMobile ? '100%' : '33.3%', borderRight: '1px solid #EBEBEB', height: '100%' }}>
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.map}
                </IconContext.Provider>
                <div className="horizontal-spacing-small body3 blue">{isMobile ? `Thailand, July 14 - July 17, 2 travelers` : 'Thailand'}</div>
              </div>
              {!isMobile && <div style={{ paddingLeft: 12, boxSizing: 'border-box', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '33.3%', borderRight: '1px solid #EBEBEB', height: '100%' }}>
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.calendar}
                </IconContext.Provider>
                <div className="horizontal-spacing-small body3 blue">July 7 - July 14</div>
              </div>}
              {!isMobile && <div style={{ paddingLeft: 12, boxSizing: 'border-box', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '33.3%', height: '100%' }}>
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.people}
                </IconContext.Provider>
                <div className="horizontal-spacing-small body3 blue">2 travelers</div>
              </div>}
            </div>
            <div style={{ marginTop: isMobile ? 18 : 0, width: isMobile ? '100%' : '25%', position: 'relative' }}>
              <div className="secondary-header4 blue">Listing Type</div>
              <Dropdown
                style={{ position: 'relative' }}
                selectedOption={selectedListingType}
                setSelectedOption={setSelectedListingType}
                style={{ width: '100%', marginTop: 4 }}
                options={['All', 'Stays', 'Experiences', 'Things To Do']}
              />
            </div>
          </div>
          <div style={{ marginBottom: isMobile ? -12 : -18, fontFamily: 'Jost-Light' }} className="vertical-spacing-medium body3 blue">{`Showing ${stays.length} results for your search in Thailand`}</div>
          {stays.slice(0, isMember ? stays.length : 3).map((stay, i) =>
            <div onClick={() => navigate(`/stays/${stay.id}`)} className="vertical-spacing-large" style={{ cursor: 'pointer', filter: !isMember && i === 2 ? 'blur(4px)' : 'none', display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} key={i}>
              <div style={{ marginTop: isMobile ? 12 : 0, width: isMobile ? '100%' : '40%' }}>
                <div style={{ display: 'inline-block' }}>
                  <div className="secondary-header4 bold blue">Stay</div>
                  <div className="blue-fill" style={{ width: 'auto', height: 0.5 }} />
                </div>
                <div className="vertical-spacing-small primary-header3 blue">{stay.name}</div>
                <div className="secondary-header3 blue">{stay.city}</div>
                <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img src={Images.aloe} style={{ width: 40, height: 40, borderRadius: 50, border: '2px solid #000814' }} />
                  <div className="horizontal-spacing-small">
                    <div className="secondary-header4 xbold blue">Curated By</div>
                    <div className="body1 blue">Reverie Team</div>
                  </div>
                </div>
                <div className="vertical-spacing-xsmall body3 blue">“Seaview Cocoon offers a tranquil escape for those seeking to heal and empower their mind, body, and spirit...”</div>
                <div className="vertical-spacing-medium secondary-header4 xbold blue">The Space</div>
                <div className="vertical-spacing-xsmall body3 blue">{`Up to ${stay.max_people} guests, ${stay.num_bedrooms} bedroom(s), ${stay.num_bathrooms} bathroom(s)`}</div>
                <div className="vertical-spacing-medium secondary-header4 xbold blue">Price</div>
                <div className="vertical-spacing-xsmall body3 blue">{`$${stay.price}/night`}</div>
                <div className="vertical-spacing-large blue-fill" style={{ width: '100%', height: 0.5 }} />
              </div>
              {isMobile ?
                <Carousel
                  currentIndex={stay.carousel_index}
                  setCurrentIndex={(index) => setCarouselIndex(stay, index)}
                  style={{ borderRadius: 10, width: '100%', height: 450 }}
                  images={stay.media.map(mediaItem => mediaItem.url)}
                  showCounter={true}
                />
                :
                <div style={{ display: 'flex', flexDirection: 'row', width: isMobile ? '100%' : '56%', height: 375 }}>
                  <img style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10, height: '100%', width: '45%', objectFit: 'cover' }} src={stay.media[0].url} />
                  <div style={{ paddingLeft: 6, width: '55%', height: '100%' }}>
                    <img style={{ borderTopRightRadius: 10, height: '49.2%', width: '100%', objectFit: 'cover' }} src={stay.media[1].url} />
                    <img style={{ borderBottomRightRadius: 10, marginTop: 2, height: '49.2%', width: '100%', objectFit: 'cover' }} src={stay.media[2].url} />
                  </div>
                </div>
              }
            </div>
          )}
          {!isMember &&
            <GateModal
              setMemberModalVisible={props.setMemberModalVisible}
              setSignInModalVisible={props.setSignInModalVisible}
              isMobile={isMobile}
              user={user}
            />
          }
        </div>
      </div>
    </div>
  );
}

const GateModal = (props) => {
  const {
    setMemberModalVisible,
    setSignInModalVisible,
    isMobile,
    user,
  } = props;
  return (
    <div className="blue-fill " style={{ marginBottom: -24, marginLeft: isMobile ? -4 : -14, position: 'relative', zIndex: 5, marginTop: isMobile ? -250 : -200, boxSizing: 'border-box', borderRadius: 10, width: isMobile ? '102%' : '103%', padding: '24px 36px 24px 36px' }}>
      <div style={{ WebkitTextStrokeColor: '#000814' }} className="primary-header3 white">Want to see more? Join the club.</div>
      <div style={{ width: isMobile ? '100%' : '60%' }} className="vertical-spacing-small body2 white">Become a <span className="bold">Reverie Club member</span> to <span className="bold">access this member-exclusive content</span> and our entire <span className="bold">curated catalogue of travel.</span></div>
      <div onClick={() => setMemberModalVisible(true)} className="white-fill vertical-spacing-medium" style={{ cursor: 'pointer', borderRadius: 5, padding: '8px 16px 8px 16px', display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
        <div className="body2 bold blue">Join the club</div>
        <IconContext.Provider value={{
            size: 24,
            className: 'horizontal-spacing-small',
            color: '#000814'
          }}>
          <div style={{ marginTop: 4 }}>
            {Icons.BsArrowRightCircle}
          </div>
        </IconContext.Provider>
      </div>
      {user === null && <div className="vertical-spacing-medium body3 white">Already a member? <span onClick={() => setSignInModalVisible(true)} className="bold" style={{ cursor: 'pointer', textDecoration: 'underline' }}>Sign in to your account</span></div>}
    </div>
  )
}

export default SearchResultsScreen;
