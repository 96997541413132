import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../styles/Dropdown.css';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';

const DropdownMenu = (props) => {

  const { buttonText, style, options } = props;

    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => setShowOptions(!showOptions);

    return (
        <div onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)} style={style} className="dropdown">
            <div
              style={{ borderBottom: 'none' }}
              className="dropdown-button"
            >
              <div className="secondary-header3 blue">{buttonText}</div>
              <IconContext.Provider value={{
                  size: 14,
                  className: 'horizontal-spacing-xsmall',
                  color: '#000814',
                }}>
                {Icons.AiFillCaretDown}
              </IconContext.Provider>
            </div>
            <CSSTransition
                in={showOptions}
                timeout={200}
                classNames="dropdown-transition"
                unmountOnExit
            >
                <div className="dropdown-options offwhite-fill">
                    {options.map(option => (
                        <div key={option} className="dropdown-menu-option">
                          <div style={{ color: 'inherit' }} onClick={() => props.onOptionClick('/search')} className="body4 blue">{option}</div>
                        </div>
                    ))}
                </div>
            </CSSTransition>
        </div>
    );
}

export default DropdownMenu;
