import '../App.css';
import '../styles/LandingPage.css';
import {Fade, Reveal} from "react-awesome-reveal";
import React, {useState, useRef, useEffect} from 'react';
import axios from "axios";
import {ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {TailSpin} from 'react-loader-spinner';
import 'animate.css';
import {keyframes} from "@emotion/react";
import {motion, useScroll, useTransform} from "framer-motion";
import {useMediaQuery} from 'react-responsive';
import * as amplitude from '@amplitude/analytics-browser';
import {useNavigate} from 'react-router-dom';
import {initializeApp} from "firebase/app";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

const Destinations = [
  {
    name: 'Thailand',
    cover_image: require('../images/thailand_cover.jpg'),
    description: 'The land of the elephants.',
  },
  {
    name: 'Iceland',
    cover_image: require('../images/iceland_cover.jpg'),
    description: 'The land of fire and ice.',
  },
  {
    name: 'Japan',
    cover_image: require('../images/japan_cover.jpg'),
    description: 'The land of controlled chaos.',
  },
];

const Testimonials = [
  {
    name: 'Christine',
    image: require('../images/christine.png'),
    trip: 'Thailand',
    quote: `“.”`
  },
  {
    name: 'Avi',
    image: require('../images/avi.png'),
    trip: 'Japan',
    quote: `“Using Reverie has %sparked my love for travel% again. It %feels like I'm a local% with all the %hidden gems% I'm able to discover.”`
  },
  {
    name: 'Melissa',
    image: require('../images/japan_cover.jpg'),
    trip: 'Korea',
    quote: `“%It takes all the hard parts out% of travel research and booking. %I love all the TikToks and videos% that are included because I can quickly see which are the %right things for my style of travel.%”`
  },
];

function LandingPage(props) {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [featuredListings, setFeaturedListings] = useState([]);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const fetchFeaturedListings = async () => {
    const featured = [];

    const staysQuery = query(collection(db, 'stays'));
    const staysSnapshot = await getDocs(staysQuery);
    staysSnapshot.forEach((doc) => {
      const staysObj = doc.data();
      featured.push({
        id: doc.id,
        type: 'stay',
        ...staysObj
      })
    });

    const experiencesQuery = query(collection(db, 'experiences'));
    const expSnapshot = await getDocs(experiencesQuery);
    expSnapshot.forEach((doc) => {
      const experienceObj = doc.data();
      featured.push({
        id: doc.id,
        type: 'experience',
        ...experienceObj
      })
    });

    setFeaturedListings(featured);
  }

  const rankings = [
    'jaiyen',
    'canyoneer_bali',
    'tabitabi',
    'zengarden',
    'emerald',
    'atv_kohsamui',
    'rayavadee',
    'oasis',
    'pavillions',
    'kayak_krabi',
    'raijin',
  ];

  useEffect(() => {
    fetchFeaturedListings();
    amplitude.track('view_landing_page');
    window.addEventListener('scroll', handleScroll, {passive: true});
    const slider = document.querySelector('.listing-row');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          amplitude.track('view_' + entry.target.id, {
            surface: 'landing_page',
          });
        }
      });
    }, {
      threshold: 0.5,
    });

    document.querySelectorAll('.observe').forEach((i) => {
      observer.observe(i);
    });

    return() => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    document.getElementsByTagName('video')[0].volume = Math.max(1 - (scrolled * 6), 0);
  };

  const featuredToShow = featuredListings.filter(listing => rankings.includes(listing.id));
  featuredToShow.sort((a, b) => rankings.indexOf(a.id) - rankings.indexOf(b.id));

  return (<div>
    <div id="video_hero" className="observe">
      <div className="video-dim" />
      <img style={{ height: 625 }} className="landing-image-hero" src={require('../images/cover.jpg')} />
      <div className="hero-text">
        <Fade damping={0.2} delay={0} direction="up" cascade="cascade">
          <div style={{ fontSize: isMobile ? 36 : 64 }} className="primary-header2 white">Book the most unique stays & experiences around the world</div>
          <div className="vertical-spacing-medium accent-fill" style={{ textAlign: 'center', alignSelf: 'center', padding: '12px 24px 12px 24px', cursor: 'pointer', borderRadius: 5, display: 'inline-block' }}>
            <div className="secondary-header2 black">explore now</div>
          </div>
        </Fade>
      </div>
    </div>
    <div style={{ paddingTop: 72, paddingBottom: 72, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100vw' }} className="full-content-container black-fill">
      <Fade direction="up" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', width: '85%', alignSelf: 'center', alignItems: 'center' }} cascade damping={0.2}>
        <div style={{ fontFamily: 'Jost-ExtraLight', width: isMobile ? '80%' : '100%' }} className="secondary-header2 accent">Recommendations You Can Trust</div>
        <div className="primary-header2 white vertical-spacing-medium">We've curated the best of travel for you.</div>
        <div style={{ width: isMobile ? '100%' : '50%', alignSelf: 'center' }} className="body1 white vertical-spacing-medium">Why? So you can <span className="normal accent">plan your dream trip</span> filled with beautiful hotels, authentic experiences, and unique spots - <span className="normal accent">without hours of research.</span></div>
      </Fade>
      <div id="explore_listings_section" style={{ paddingLeft: 0 }} className="observe listing-row vertical-spacing-large">
        {
          featuredToShow.map((listing, i) => <div onClick={() => {
              amplitude.track('click_listing', {
                surface: 'landing_page',
                section: 'example_listings_section',
                listing_id: listing.id,
              });
              const win = window.open(`https://travelreverie.com/${listing.type === 'experience' ? 'experiences' : 'stays'}/${listing.id}`, '_blank');
              win.focus();
            }} className="listing-item">
            <img className="listing-img" src={listing.cover_image_url}/>
            <div className="listing-dim"/>
            <div className="listing-text">
              <div style={{ WebkitTextStrokeWidth: '0em' }} className="primary-header3 white listing-header">{listing.name}</div>
              <div className="secondary-header3 white vertical-spacing-small listing-subheader">{listing.city + ', ' + listing.country}</div>
            </div>
          </div>)
        }
      </div>
      <Fade damping={0.2} delay={0} direction="up" cascade="cascade">
        <div className="vertical-spacing-large accent-fill" style={{ textAlign: 'center', alignSelf: 'center', padding: '12px 24px 12px 24px', cursor: 'pointer', borderRadius: 5, display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
          <div className="secondary-header2 black">explore now</div>
        </div>
      </Fade>
    </div>
    <div className="full-content-container" style={{ paddingTop: 72, paddingBottom: 72 }}>
      <img src={require('../images/greenhill.jpg')} style={{ objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }} />
      <div className="video-dim" />
      <div style={{ textAlign: 'center', width: isMobile ? '80%' : '100%', zIndex: 10 }} className="primary-header2 white">What Reverie Members Are Saying</div>
      <div id="testimonials_section" className="observe" style={{ zIndex: 10, marginTop: isMobile ? 24 : 64, width: '80%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'center' : 'flex-start', justifyContent: 'space-between' }}>
      {Testimonials.map(testimonial =>
        <div className="black-fill" style={{ marginTop: isMobile ? 24 : 0, borderRadius: 10, paddingLeft: 18, paddingRight: 18, paddingTop: 18, paddingBottom: 12, width: isMobile ? '100%' : '29%', textAlign: 'left' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img src={testimonial.image} style={{ height: 60, width: 60, borderRadius: 60 }} />
              <div className="horizontal-spacing-small primary-header4 white">{testimonial.name}</div>
            </div>
            <IconContext.Provider value={{
                size: 32,
                color: '#88A9C3'
              }}>
              <div>
                {Icons.RiDoubleQuotesR}
              </div>
            </IconContext.Provider>
          </div>
          <div style={{ lineHeight: '1.7em' }} className="vertical-spacing-small body2 white">
            {testimonial.quote.split('%').map((text, i) =>
              (i % 2 != 0 ?
                <span className="normal accent">{text}</span>
                :
                <span className="body2 white">{text}</span>
              )
            )}
          </div>
        </div>
        )}
      </div>
      <Fade damping={0.2} delay={0} direction="up" cascade="cascade">
        <div className="vertical-spacing-large accent-fill" style={{ textAlign: 'center', alignSelf: 'center', padding: '12px 24px 12px 24px', cursor: 'pointer', borderRadius: 5, display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
          <div className="secondary-header2 black">explore now</div>
        </div>
      </Fade>
    </div>
    <div style={{ position: 'relative', paddingTop: 72, paddingBottom: 72, display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="full-content-container black-fill">
      <div style={{ alignSelf: 'center', textAlign: 'center', width: isMobile ? '80%' : '100%' }} className="primary-header2 white">Why Travelers Love Reverie</div>
      <div className="quality-row">
        <div direction="left">
          <div className="quality-info">
            <div className="accent quality-number">01</div>
            <div className="primary-header3 white">The most unique spots in travel.</div>
            <div style={{ lineHeight: '1.7em' }} className="body2 lightergray vertical-spacing-medium">We curate the spots that are <span className="normal accent">truly unique & extraordinary</span> so that you <span className="normal accent">never go on a boring, touristy trip again.</span></div>
          </div>
        </div>
        <div direction="right">
          <img className="quality-img" loading="lazy" src={require('../images/quality1.png')}/>
        </div>
      </div>
      <div className="quality-row" style={{
          flexDirection: isMobile
            ? 'column'
            : 'row'
        }}>
        <div direction="left">
          <img className="quality-img" loading="lazy" src={require('../images/quality2.png')}/>
        </div>
        <div direction="right">
          <div className="quality-info">
            <div className="accent quality-number">02</div>
            <div className="primary-header3 white">Trusted recs from real travelers.</div>
            <div style={{ lineHeight: '1.7em' }} className="body2 lightergray vertical-spacing-medium"><span className="normal accent">Feel confident </span>about where you're going - see and hear from <span className="normal accent">travelers that have actually been there.</span></div>
          </div>
        </div>
      </div>
      <div className="quality-row">
        <div direction="left">
          <div className="quality-info">
            <div className="accent quality-number">03</div>
            <div className="primary-header3 white">Insider knowledge without the research.</div>
            <div style={{ lineHeight: '1.7em' }} className="body2 lightergray vertical-spacing-medium"><span className="normal accent">Discover tips</span> that'll make you <span className="normal accent">feel like a travel expert</span> - from how to get a more <span className="normal accent">local experience</span> to the best times to <span className="normal accent">avoid the crowds,</span> we've researched it all.</div>
          </div>
        </div>
        <div direction="right">
          <img className="quality-img" loading="lazy" src={require('../images/quality3.png')}/>
        </div>
      </div>
      <Fade damping={0.2} delay={0} direction="up" cascade="cascade">
        <div className="vertical-spacing-xlarge accent-fill" style={{ textAlign: 'center', alignSelf: 'center', padding: '12px 24px 12px 24px', cursor: 'pointer', borderRadius: 5, display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
          <div className="secondary-header2 black">Explore Now</div>
        </div>
      </Fade>
    </div>
    <div style={{ paddingTop: 48, paddingBottom: 48, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100vw', textAlign: 'center' }} className="full-content-container accent-fill">
      <div className="secondary-header2 black">The Reverie Standard</div>
      <div className="vertical-spacing-small primary-header2 black">Every moment spent traveling is a gift.</div>
      <div style={{ width: isMobile ? '80%' : '100%' }} className="vertical-spacing-medium body1 normal black">Don't waste it on boring, touristy trips. Let Reverie help you discover the experiences that make you feel alive.</div>
    </div>
    <div style={{ paddingTop: 72, paddingBottom: 72, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100vw' }} className="full-content-container black-fill">
      <div style={{ zIndex: 2 }} className="primary-header2 white">Explore Destinations</div>
      <div style={{ zIndex: 2, marginTop: isMobile ? 0 : 48, display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between', width: isMobile ? '85%' : '70%' }}>
        {Destinations.map((destination, i) =>
          <div style={{ position: 'relative', marginTop: isMobile ? 24 : 0, cursor: 'pointer', overflow: 'hidden', height: isMobile ? 'auto' : '29vw', width: isMobile ? '100%' : '31%', position: 'relative', borderRadius: 10 }}>
            <img src={destination.cover_image} style={{ height: '100%', width: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, objectFit: 'cover' }} />
            <div style={{ height: '100%', position: 'relative', zIndex: 8, textAlign: 'left', backgroundColor: 'rgba(0,0,0,0.35)' }}>
              <div style={{ padding: 18 }}>
                <div className="secondary-header2 white">Explore</div>
                <div style={{ fontSize: 48, WebkitTextStrokeWidth: '0em' }} className="vertical-spacing-small primary-header1 white">{destination.name}</div>
                <div className="white-fill" style={{ marginTop: isMobile ? 24 : 48, height: 1, width: '40%' }} />
                <div style={{ marginTop: isMobile ? 24 : 48, width: '100%' }} className="white body1">{destination.description}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ zIndex: 2, textAlign: 'center', width: isMobile ? '90%' : '100%' }} className="vertical-spacing-large body1 white">We’re adding new destinations every week. Don’t see a destination you need?</div>
      <div style={{ zIndex: 2, textAlign: 'center', width: isMobile ? '80%' : '100%' }} className="vertical-spacing-xsmall body1 white"><span style={{ textDecoration: 'underline' }} className="normal accent">Request a destination</span> and we’ll add it within a week.</div>
    </div>
  </div>);
}

export default LandingPage;
