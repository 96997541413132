import React, {useState, useEffect} from 'react';
import '../App.css';
import {Collapse} from 'react-collapse';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import {useMediaQuery} from 'react-responsive';
import Calendar from 'react-calendar';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

function SubscriptionSuccessScreen(props) {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const navigate = useNavigate();

  return (
    <div className="white-fill" style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start', width: isMobile ? '85%' : '90%', alignSelf: 'center', paddingTop: isMobile ? 48 : 96, paddingBottom: isMobile ? 48 : 96 }}>
        <div style={{ marginLeft: isMobile ? 0 : 48, zIndex: 2, width: isMobile ? '100%' : '50%' }}>
          <div className="vertical-spacing-large primary-header2 blue">You're in.</div>
          <div className="vertical-spacing-medium body2 blue" style={{ width: '100%' }}><span className="bold">Welcome to the club.</span> You’ve successfully subscribed for an <span className="bold">annual membership</span> to <span className="bold">The Reverie Club.</span> If you have any questions about your membership or subscription, please email us at <span className="bold">support@travelreverie.com</span>.</div>
          <div onClick={() => navigate('/search')} className=" vertical-spacing-xxlarge" style={{ display: 'inline-block', cursor: 'pointer' }}>
            <div className="v2-button-text secondary-header3 bold blue">Start exploring</div>
            <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'right' }} />
          </div>
        </div>
        <img style={{ marginTop: isMobile ? 48 : 0, marginLeft: isMobile ? 0 : -96, height: isMobile ? 'auto' : '40vw', width: isMobile ? '100%' : '50%', objectFit: 'contain' }} src={require('../images/hero.png')} />
      </div>
    </div>
  );
}

export default SubscriptionSuccessScreen;
