import {
  SlWallet,
  SlPeople,
  SlClock,
  SlMap,
  SlCalender,
  SlPlane,
  SlArrowLeft,
  SlArrowRight,
  SlHome,
  SlList,
  SlScreenDesktop,
  SlFire,
  SlBubbles,
  SlCreditCard,
} from "react-icons/sl";
import {
  BsX,
  BsChevronLeft,
  BsChevronRight,
  BsWind,
  BsArrowRightCircle,
  BsArrowLeftCircle,
  BsSun,
  BsSunrise,
  BsBinoculars,
} from "react-icons/bs";
import {
  AiFillCamera,
  AiFillCar,
  AiFillPlayCircle,
  AiOutlineFire,
  AiOutlineCar,
  AiOutlineCamera,
  AiFillCaretDown,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { FaBed } from "react-icons/fa";
import {
  IoBedOutline,
  IoSnowSharp,
  IoWaterOutline,
} from "react-icons/io5";
import {
  MdOutlineWifi,
  MdOutlineLocalLaundryService,
  MdOutlineMicrowave,
  MdOutlineSportsMotorsports,
  MdOutlineWaterDrop,
  MdOutlineShower,
  MdOutlineBackpack,
} from "react-icons/md";
import {
  CgCoffee,
} from "react-icons/cg";
import {
  GiPressureCooker,
  GiCoconuts,
} from "react-icons/gi";
import {
  BiDish,
} from "react-icons/bi";
import {
  RiFridgeLine,
  RiDoubleQuotesR,
} from "react-icons/ri";
import {
  TbLamp2,
  TbBottle,
  TbHelmet,
  TbLifebuoy,
  TbKayak,
  TbScubaMask,
  TbSpeedboat,
} from "react-icons/tb";
import {
  CiForkAndKnife,
  CiMedicalCross,
  CiApple,
} from "react-icons/ci";

const Icons = {
  wallet: <SlWallet />,
  people: <SlPeople />,
  clock: <SlClock />,
  map: <SlMap />,
  calendar: <SlCalender />,
  plane: <SlPlane />,
  x: <BsX />,
  arrowLeft: <BsChevronLeft />,
  arrowRight: <BsChevronRight />,
  camera: <AiFillCamera />,
  play: <AiFillPlayCircle />,
  bed: <IoBedOutline />,
  home: <SlHome />,
  list: <SlList />,
  SlScreenDesktop: <SlScreenDesktop />,
  SlFire: <SlFire />,
  BsWind: <BsWind />,
  AiOutlineFire: <AiOutlineFire />,
  IoBedOutline: <IoBedOutline />,
  IoSnowSharp: <IoSnowSharp />,
  MdOutlineWifi: <MdOutlineWifi />,
  MdOutlineLocalLaundryService: <MdOutlineLocalLaundryService />,
  MdOutlineMicrowave: <MdOutlineMicrowave />,
  CgCoffee: <CgCoffee />,
  GiPressureCooker: <GiPressureCooker />,
  BiDish: <BiDish />,
  RiFridgeLine: <RiFridgeLine />,
  TbLamp2: <TbLamp2 />,
  AiOutlineCar: <AiOutlineCar />,
  CiForkAndKnife: <CiForkAndKnife />,
  TbBottle: <TbBottle />,
  MdOutlineSportsMotorsports: <MdOutlineSportsMotorsports />,
  MdOutlineWaterDrop: <MdOutlineWaterDrop />,
  TbHelmet: <TbHelmet />,
  MdOutlineShower: <MdOutlineShower />,
  CiMedicalCross: <CiMedicalCross />,
  AiOutlineCamera: <AiOutlineCamera />,
  SlBubbles: <SlBubbles />,
  TbLifebuoy: <TbLifebuoy />,
  CiApple: <CiApple />,
  BsArrowRightCircle: <BsArrowRightCircle />,
  TbKayak: <TbKayak />,
  BsArrowLeftCircle: <BsArrowLeftCircle />,
  GiCoconuts: <GiCoconuts />,
  TbScubaMask: <TbScubaMask />,
  BsSun: <BsSun />,
  IoWaterOutline: <IoWaterOutline />,
  MdOutlineBackpack: <MdOutlineBackpack />,
  TbSpeedboat: <TbSpeedboat />,
  BsSunrise: <BsSunrise />,
  BsBinoculars: <BsBinoculars />,
  SlCreditCard: <SlCreditCard />,
  AiFillCaretDown: <AiFillCaretDown />,
  AiOutlineMinus: <AiOutlineMinus />,
  AiOutlinePlus: <AiOutlinePlus />,
  AiOutlineLeft: <AiOutlineLeft />,
  AiOutlineRight: <AiOutlineRight />,
  RiDoubleQuotesR: <RiDoubleQuotesR />,
  SlArrowRight: <SlArrowRight />,
};

export default Icons;
