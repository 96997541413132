import '../App.css';
import '../styles/ExplorePage.css'
import React, {useState, useRef, useEffect} from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import Images from '../Images.js';
import {Link, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {fetchCollection, bedsToString} from '../utils/UtilFunctions.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

function ExplorePage(props) {
  const {state} = useLocation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [experiences, setExperiences] = useState([]);
  const [stays, setStays] = useState([]);
  const [standardModalVisible, setStandardModalVisible] = useState(false);

  const [searchParams] = useSearchParams();
  const queryDestinationParam = searchParams.get('destination');
  const [selectedDestination, setSelectedDestination] = useState(state !== null ? state.destination : (queryDestinationParam !== null ? queryDestinationParam : 'Thailand'));
  const [categoryToShow, setCategoryToShow] = useState('all');

  useEffect(() => {
    amplitude.track('view_explore_page', {
      initial_destination: state !== null ? state.destination : 'Thailand',

    });
    fetchCollection('stays', (results) => setStays(results));
    fetchCollection('experiences', (results) => setExperiences(results));
  }, []);

  useEffect(() => {
    if (stays.length > 0 && experiences.length > 0) {
      const listingIds = stays.concat(experiences).filter(listing => listing.country === selectedDestination).map(listing => listing.id);
      const options = {
        threshold: 0.5,
      };

      const selectObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            amplitude.track('view_destination_select_section', {
              surface: 'explore_page',

            });
          }
        });
      }, options);

      const listingObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const listing = stays.concat(experiences).find(listing => listing.id === entry.target.id);
            amplitude.track('view_listing_card', {
              surface: 'explore_page',
              listing_id: listing.id,
              country: listing.country,
              listing_type: listing.collection.slice(0, -1),

            });
          }
        });
      }, options);

      const comingSoonObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            amplitude.track('view_coming_soon_banner', {
              surface: 'explore_page',

            });
          }
        });
      }, options);

      selectObserver.observe(document.getElementById('destination-select-section'));
      document.querySelectorAll('.result-container').forEach((i) => {
        listingObserver.observe(i);
      });
      comingSoonObserver.observe(document.getElementById('coming-soon-banner'));
    }
  }, [stays, experiences, selectedDestination]);

  const Destinations = [
    {
      name: 'Thailand',
      cover_image: require('../images/thailand_cover.jpg')
    }, {
      name: 'Japan',
      cover_image: require('../images/japan_cover.jpg')
    }, {
      name: 'Indonesia',
      cover_image: require('../images/indonesia_cover.jpg')
    }
  ]

  const rankings = {
    Thailand: [
      'jaiyen',
      'emerald',
      'rayavadee',
      'oasis',
      'pavillions',
      'longtail_krabi',
      'kayak_krabi',
      'sala',
      'atv_kohsamui',
      'bophut',
    ],
    Japan: [
      'zengarden',
      'tabitabi',
      'kokyu',
      'raijin',
    ],
    Indonesia: [
      'canyoneer_bali'
    ],
  };

  let resultsToShow = [];
  switch (categoryToShow) {
    case 'all':
      resultsToShow = stays.concat(experiences);
      break;
    case 'stays':
      resultsToShow = stays;
      break;
    case 'experiences':
      resultsToShow = experiences;
      break;
  }
  resultsToShow = resultsToShow.filter(result => result.country === selectedDestination);
  resultsToShow.sort((a, b) => rankings[selectedDestination].indexOf(a.id) - rankings[selectedDestination].indexOf(b.id));
  let resultRows = [];
  for (let i = 0; i < resultsToShow.length; i += 2) {
    resultRows.push(resultsToShow.slice(i, i + 2));
  }

  return (<div className="page-container black-fill">
    {
      standardModalVisible
        ? <div className="dim-view"/>
        : null
    }
    <div style={{ alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : '42vw', padding: 36 }} className={'notify-modal' + (
        standardModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: 'black',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setStandardModalVisible(false);
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div className="primary-header4 black">The Reverie Standard</div>
      <div className="body3 black vertical-spacing-small">Each of our listings are vetted in-person by a Reverie team member and carefully selected to give you an unparalleled travel experience.</div>
      <div className="detail-divider lightergray-fill vertical-spacing-small" />
      <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="standard-number accent">1</div>
        <div className="horizontal-spacing-medium">
          <div style={{ fontFamily: 'Jost-Regular' }} className="xbold body2 black">Top 1% of Stays and Experiences</div>
          <div className="body3 black vertical-spacing-xsmall">We account for factors like location, views, guest experience, value for price, and much more to select the top 1% of stays and experiences in a destination.</div>
        </div>
      </div>
      <div className="detail-divider lightergray-fill vertical-spacing-small" />
      <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="standard-number accent">2</div>
        <div className="horizontal-spacing-medium">
          <div style={{ fontFamily: 'Jost-Regular' }} className="xbold body2 black">In-Person Vetting</div>
          <div className="body3 black vertical-spacing-xsmall">Every listing has been vetted in-person by a Reverie team member, and is consistently monitored based on every guest experience.</div>
        </div>
      </div>
      <div className="detail-divider lightergray-fill vertical-spacing-small" />
      <div className="vertical-spacing-small" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="standard-number accent">3</div>
        <div className="horizontal-spacing-medium">
          <div style={{ fontFamily: 'Jost-Regular' }} className="xbold body2 black">24/7 Concierge Support Team</div>
          <div className="body3 black vertical-spacing-xsmall">Our concierge team is available 24/7 to help with any questions or concerns that arise during your trip, so you can focus on the joy of travel.</div>
        </div>
      </div>
    </div>
    <div style={{
        width: '100vw',
        paddingBottom: 48
      }} id="destination-select-section" className="full-content-container black-fill vertical-spacing-xlarge">
      <div style={{
          position: 'relative',
          width: '100vw',
          justifyContent: isMobile ? 'flex-start' : 'center',
          height: 'auto',
          paddingLeft: isMobile ? 24 : 0,
          paddingRight: isMobile ? 24 : 0,
        }} className="listing-row vertical-spacing-xlarge">
        {
          Destinations.map((destination, i) => <div onClick={() => {
            setSelectedDestination(destination.name);
            amplitude.track('destination_clicked', {
              surface: 'explore_page_header',
              destination: destination.name,

            });
          }} className={selectedDestination === destination.name
              ? 'destination-selected destination-item'
              : 'destination-item'}>
            <img className="destination-img" src={destination.cover_image}/>
            <div className="listing-dim" style={{ backgroundColor: 'black', opacity: 0.1 }}/>
            <div className="destination-text primary-header2 white">{destination.name}</div>
          </div>)
        }
      </div>
      <div className="primary-header2 white vertical-spacing-xlarge">{'Explore ' + selectedDestination}</div>
      <div className="category-row vertical-spacing-large">
        <div style={{ textAlign: isMobile ? 'center' : 'right' }} onClick={() => {
          setCategoryToShow('all');
          amplitude.track('filter_clicked', {
            surface: 'explore_page',
            filter_name: 'all',

          });
        }} className={'category-button ' + (
            categoryToShow === 'all'
            ? 'bold secondary-header2 white'
            : 'secondary-header2 lightgray')}>All</div>
        <div onClick={() => {
          setCategoryToShow('stays');
          amplitude.track('filter_clicked', {
            surface: 'explore_page',
            filter_name: 'stays',

          });
        }} className={'category-button ' + (
            categoryToShow === 'stays'
            ? 'bold secondary-header2 white'
            : 'secondary-header2 lightgray')}>Stays</div>
        <div style={{ textAlign: isMobile ? 'center' : 'left' }} onClick={() => {
          setCategoryToShow('experiences');
          amplitude.track('filter_clicked', {
            surface: 'explore_page',
            filter_name: 'experiences',

          });
        }} className={'category-button ' + (
            categoryToShow === 'experiences'
            ? 'bold secondary-header2 white'
            : 'secondary-header2 lightgray')}>Experiences</div>
      </div>
    </div>
    <div className="full-content-container offwhite-fill">
        <div style={{ width: '80%', fontFamily: 'Jost-Regular' }} className="secondary-header3 black vertical-spacing-medium">{
            'Showing ' + resultsToShow.length + ' ' + (
              categoryToShow === 'all'
              ? 'stays & experiences'
              : categoryToShow) + ' in ' + selectedDestination
          }</div>
        <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="body3 black">All listings are approved by</div>
          <div onClick={() => {
            setStandardModalVisible(true);
            amplitude.track('standard_learn_clicked', {
              surface: 'explore_results',

            });
          }} style={{ borderBottom: '1px solid #88A9C3', marginLeft: 6 }} className="body3-hover accent">The Reverie Standard</div>
        </div>
        {
          resultRows.map((row, i) =>
          <div className="result-row" key={i}>
          {row.map((item, j) => {
            const featureItems = item.collection === 'experiences'
              ? [
                {
                  icon: Icons.wallet,
                  header: 'Price',
                  value: '$' + item.price + (item.id === 'longtail_krabi' ? '/group' : '/person'),
                }, {
                  icon: Icons.people,
                  header: 'Group Size',
                  value: item.min_people + ' - ' + item.max_people + ' people'
                }, {
                  icon: Icons.clock,
                  header: 'Duration',
                  value: item.duration_hours + ' hours'
                }, {
                  icon: Icons.map,
                  header: 'Location',
                  value: item.city
                }
              ]
              : [
                {
                  icon: Icons.wallet,
                  header: 'Price',
                  value: '$' + item.price + '/night'
                }, {
                  icon: Icons.people,
                  header: 'Sleeps',
                  value: item.max_people + ' guests'
                }, {
                  icon: Icons.bed,
                  header: 'Bedrooms',
                  value: item.num_bedrooms === 0 ? 'Studio' : item.num_bedrooms,
                },
                {
                  icon: Icons.MdOutlineShower,
                  header: 'Bathrooms',
                  value: item.num_bathrooms,
                }
              ];

            return (<div id={item.id} onClick={() => {
              navigate((item.collection === 'experiences' ? '/experiences/' : '/stays/') + item.id, { state: item });
              amplitude.track('listing_clicked', {
                surface: 'explore_page',
                listing_id: item.id,
                country: item.country,
                listing_type: item.collection.slice(0, -1),

              });
            }} key={j} className="result-container">
              <div className="primary-header3 black">{item.name}</div>
              <div className="secondary-header3 black">{item.city + ', ' + item.country}</div>
              {
                'trailer_url' in item
                  ? <video className="result-img vertical-spacing-small" onMouseOver={event => event.target.play()} onMouseOut={event => event.target.pause()} src={item.trailer_url}></video>
                  : <img className="result-img vertical-spacing-small" src={item.cover_image_url}/>
              }
              <div style={{
                  width: '100%'
                }} className="feature-row">
                {
                  featureItems.map((feature, j) => <div style={{ width: isMobile ? '50%' : '25%', marginTop: isMobile ? 18 : 24, display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={j}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <IconContext.Provider value={{
                          size: 16,
                          color: '#787878'
                        }}>
                        <div>
                          {feature.icon}
                        </div>
                      </IconContext.Provider>
                      <div style={{
                          marginTop: -4
                        }} className="secondary-header3 darkgray horizontal-spacing-small">{feature.header}</div>
                    </div>
                    <div className="body2 black vertical-spacing-xsmall">{feature.value}</div>
                  </div>)
                }
              </div>
            </div>);
          })}
        </div>)
        }
        <div id="coming-soon-banner" className="full-content-container black-fill vertical-spacing-large">
          <div className="narrow-content-container">
            <div className="bold secondary-header3 accent">The Future of Travel is Here</div>
            <div style={{ width: '100%' }} className="primary-header2 white vertical-spacing-xxsmall">{'Launching in Q2 2023.'}</div>
            <div className="body2 white vertical-spacing-small">{'Sign up for the Reverie waitlist to be the first to get access when our product launches.'}</div>
            <div className="outline-button vertical-spacing-large" onClick={() => {
                props.setWaitlistModalVisible(true);
                amplitude.track('join_waitlist_clicked', { surface: 'explore_coming_soon_banner' });
              }}>
              <div style={{ color: 'inherit' }} className="bold secondary-header2">{'Join the waitlist'}</div>
            </div>
            <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
              <div className="body2 white">Going somewhere else?</div>
              <div onClick={() => {
                props.setRequestModalVisible(true);
                amplitude.track('destination_request_clicked', { surface: 'explore_coming_soon_banner',  });
              }} style={{ borderBottom: '1px solid #88A9C3', marginLeft: isMobile ? 0 : 6 }} className="body2-hover accent">Request a destination</div>
            </div>
          </div>
        </div>
      </div>
  </div>);
}

export default ExplorePage;
