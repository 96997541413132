import '../App.css';
import '../styles/LandingPage.css';
import {Fade, Reveal} from "react-awesome-reveal";
import React, {useState, useRef, useEffect} from 'react';
import axios from "axios";
import {useMediaQuery} from 'react-responsive';
import * as amplitude from '@amplitude/analytics-browser';
import {useNavigate} from 'react-router-dom';
import {initializeApp} from "firebase/app";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

const Destinations = [
  {
    name: 'Thailand',
    image: require('../images/thailand_guide.jpg'),
  },
  {
    name: 'Iceland',
    image: require('../images/iceland_guide.jpg'),
  },
  {
    name: 'Japan',
    image: require('../images/japan_guide.png'),
  },
];

const Rankings = [
  'jaiyen',
  'canyoneer_bali',
  'tabitabi',
  'zengarden',
  'emerald',
  'atv_kohsamui',
  'rayavadee',
  'oasis',
  'pavillions',
  'kayak_krabi',
  'raijin',
];

const FeaturedDetails = {
  jaiyen: {
    height: '15vw',
    mobile_width: '70vw',
  },
  canyoneer_bali: {
    height: '24vw',
    mobile_width: '65vw',
  },
  tabitabi: {
    height: '20vw',
    mobile_width: '60vw',
  },
  zengarden: {
    height: '26vw',
    mobile_width: '80vw',
  },
  emerald: {
    height: '18vw',
    mobile_width: '65vw',
  },
  atv_kohsamui: {
    height: '15vw',
    mobile_width: '85vw',
  },
  rayavadee: {
    height: '21vw',
    mobile_width: '75vw',
  },
  oasis: {
    height: '23vw',
    mobile_width: '80vw',
  },
  pavillions: {
    height: '16vw',
    mobile_width: '50vw',
  },
  kayak_krabi: {
    height: '22vw',
    mobile_width: '70vw',
  },
  raijin: {
    height: '14vw',
    mobile_width: '70vw',
  },
}

function LandingPageV2(props) {
  const [user, setUser] = useState(null);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [featuredListings, setFeaturedListings] = useState([]);
  const navigate = useNavigate();

  const fetchFeaturedListings = async () => {
    const featured = [];

    const staysQuery = query(collection(db, 'stays'));
    const staysSnapshot = await getDocs(staysQuery);
    staysSnapshot.forEach((doc) => {
      const staysObj = doc.data();
      featured.push({
        id: doc.id,
        type: 'stays',
        ...staysObj
      })
    });

    const experiencesQuery = query(collection(db, 'experiences'));
    const expSnapshot = await getDocs(experiencesQuery);
    expSnapshot.forEach((doc) => {
      const experienceObj = doc.data();
      featured.push({
        id: doc.id,
        type: 'experiences',
        ...experienceObj
      })
    });

    setFeaturedListings(featured);
  }

  useEffect(() => {
    fetchFeaturedListings();
  }, []);

  const featuredToShow = featuredListings.filter(listing => Rankings.includes(listing.id));
  featuredToShow.sort((a, b) => Rankings.indexOf(a.id) - Rankings.indexOf(b.id));

  const isMember = user !== null && user.stripe_subscription_status === 'active';

  const handleCTA = () => {
    if (isMember) {
      navigate('/search');
    } else {
      props.setMemberModalVisible(true);
    }
  }

  const cta = isMember ? 'Explore' : 'Join the club';

  return (
    <div style={{ width: '100vw', paddingTop: 96, display: 'flex', flexDirection: 'column', overflowX: 'hidden' }} className="white-fill">
      <HeroSection handleCTA={() => handleCTA()} cta={cta}/>
      <AffirmationsSection />
      <CurationSection listings={featuredToShow} handleCTA={() => handleCTA()} cta={cta}/>
      <TestimonialSection handleCTA={() => handleCTA()} cta={cta}/>
      <WhySection handleCTA={() => handleCTA()} cta={cta}/>
      <ExploreSection />
    </div>
  );
}

const HeroSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const {
    handleCTA,
    cta
  } = props;

  return (isMobile ?
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '85%', alignSelf: 'center' }}>
      <div style={{ fontSize: isMobile ? 40 : 64 }} className="primary-header2 blue">Book the most unique stays & experiences in travel.</div>
      <img className="vertical-spacing-xlarge" style={{ height: 'auto', width: '100%', objectFit: 'contain' }} src={require('../images/hero.png')} />
      <div className="vertical-spacing-xlarge body2 blue" style={{ width: '100%' }}><span className="bold">Plan your dream trip</span> filled with breathtaking hotels, authentic experiences, and local spots - <span className="bold">without hours of research.</span></div>
      <div onClick={() => handleCTA()} className="vertical-spacing-large" style={{ alignSelf: 'flex-start', display: 'inline-block', cursor: 'pointer' }}>
        <div className="v2-button-text secondary-header3 blue">{cta}</div>
        <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'right' }} />
      </div>
    </div>
    :
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%', alignSelf: 'center' }}>
      <div style={{ marginLeft: 48, zIndex: 2, width: '50%' }}>
        <div className="primary-header2 blue">Book the most unique stays & experiences in travel.</div>
        <div className="vertical-spacing-xlarge body2 blue" style={{ width: '60%' }}><span className="bold">Plan your dream trip</span> filled with breathtaking hotels, authentic experiences, and local spots - <span className="bold">without hours of research.</span></div>
        <div onClick={() => handleCTA()} className=" vertical-spacing-large" style={{ display: 'inline-block', cursor: 'pointer' }}>
          <div className="v2-button-text secondary-header3 blue">{cta}</div>
          <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'right' }} />
        </div>
      </div>
      <img style={{ marginLeft: -96, height: '40vw', width: '50%', objectFit: 'contain' }} src={require('../images/hero.png')} />
    </div>
  );
}

const AffirmationsSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div style={{ marginTop: 72, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '110%', alignSelf: 'center' }}>
      <div className="blue-fill" style={{ height: 1, width: '100%'}} />
      <div className="vertical-spacing-small primary-header6 blue" style={{ wordWrap: 'break-word', textOverflow: '', overflow: 'hidden', maxHeight: '4em', lineHeight: '1em', whiteSpace: isMobile ? 'wrap' : 'nowrap' }}>Say no to boring trips. Say no to rude hosts. Say no to tourist traps. Say no to overpriced hotels. Say no to inauthentic experiences. Say no to chain restaurants. Say no to boring trips. Say no to rude hosts. Say no to tourist traps. Say no to overpriced hotels. Say no to inauthentic experiences. Say no to chain restaurants.</div>
      <div className="blue-fill vertical-spacing-small" style={{ height: 1, width: '100%'}} />
    </div>
  );
}

const CurationSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const navigate = useNavigate();
  const {
    handleCTA,
    cta,
  } = props;

  return (
    <div style={{ paddingTop: 72, paddingBottom: isMobile ? 24 : 72, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignSelf: 'center' }}>
      <div className="primary-header3 blue" style={{ textAlign: 'center', width: '80%' }}>We've curated the best of travel for you.</div>
      <div className="body2 blue vertical-spacing-medium" style={{ textAlign: 'center', width: isMobile ? '80%' : '40%', lineHeight: '1.7em' }}>Why? So you <span className="bold">never go on a boring, touristy trip again.</span> Discover <span className="bold">trusted recommendations from real travelers</span> that have actually been there.</div>
      <div onClick={() => handleCTA()} className=" vertical-spacing-large" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="v2-button-text secondary-header3 blue">{cta}</div>
        <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%' }} />
      </div>
      <div className="curated-collage-row vertical-spacing-large" style={{ marginLeft: 0, position: 'relative', WebkitOverflowScrolling: 'touch', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'hidden', boxSizing: 'border-box', width: '100vw', height: isMobile ? '50vh' : '30vw', alignItems: 'center' }}>
        {props.listings.map((listing, i) =>
          <div onClick={() => navigate(`/${listing.type}/${listing.id}`)} style={{ cursor: 'pointer', flex: '0 0 auto', position: 'relative', marginRight: 24 }}>
            <img style={{ width: isMobile ? FeaturedDetails[listing.id].mobile_width : '100%', height: isMobile ? 'auto' : FeaturedDetails[listing.id].height, objectFit: 'contain' }} src={listing.cover_image_url} />
            <div className="primary-header6 blue vertical-spacing-xsmall">{listing.name}</div>
            <div className="secondary-header4 blue">{listing.country}</div>
          </div>
        )}
      </div>
    </div>
  );
}

const TestimonialSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const {
    handleCTA,
    cta
  } = props;

  return (isMobile ?
    <div style={{ paddingTop: 72, paddingBottom: 72, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', alignSelf: 'center' }}>
      <div style={{ width: '85%', textAlign: 'right', alignSelf: 'center', alignItems: 'flex-end' }}>
        <div className="primary-header3 blue">Tailored to the modern traveler.</div>
        <div className="body2 blue vertical-spacing-medium">For the ones that like to mix <span className="bold">adventure with luxury.</span>The ones that love <span className="bold">street food and Michelin star.</span><span className="bold">We get you.</span></div>
        <div onClick={() => handleCTA()} className="vertical-spacing-large" style={{ display: 'inline-block', cursor: 'pointer' }}>
          <div className="v2-button-text secondary-header3 blue">{cta}</div>
          <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'left' }} />
        </div>
      </div>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <img style={{ height: 300, width: '45%', objectFit: 'cover' }} src={require('../images/testimonial1.jpg')} />
        <div style={{ marginLeft: 24, width: '45%', textAlign: 'left' }}>
          <div className="vertical-spacing-small primary-header4 blue">Christine.</div>
          <div className="body4 blue vertical-spacing-medium" style={{ width: '100%' }}>"Using Reverie has <span className="bold">sparked my love for travel</span> again. It <span className="bold">feels like I’m a local</span> with all the hidden gems I’m able to discover."</div>
        </div>
      </div>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <img style={{ height: 300, width: '45%', objectFit: 'cover' }} src={require('../images/mel.jpg')} />
        <div style={{ marginLeft: 24, width: '45%', textAlign: 'left' }}>
          <div className="vertical-spacing-small primary-header4 blue">Melissa.</div>
          <div className="body4 blue vertical-spacing-medium" style={{ width: '100%' }}>"I love all the TikToks and videos that are included because I can quickly see which are the <span className="bold">right things for my style of travel."</span></div>
        </div>
      </div>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <img style={{ height: 300, width: '45%', objectFit: 'cover' }} src={require('../images/avi.jpg')} />
        <div style={{ marginLeft: 24, width: '45%', textAlign: 'left' }}>
          <div className="vertical-spacing-small primary-header4 blue">Avi.</div>
          <div className="body4 blue vertical-spacing-medium" style={{ width: '100%' }}>"I can’t imagine booking another trip without Reverie - they understand <span className="bold">what young travelers are looking for</span> perfectly."</div>
        </div>
      </div>
    </div>
    :
    <div style={{ paddingTop: 72, paddingBottom: 72, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '68%', alignSelf: 'center' }}>
      <div style={{ width: '43%' }}>
        <img style={{ height: '69vh', width: '100%', objectFit: 'cover' }} src={require('../images/testimonial1.jpg')} />
        <div className="primary-header6 blue">Christine.</div>
        <div className="body3 blue vertical-spacing-xsmall horizontal-spacing-medium" style={{ width: '80%' }}>"Using Reverie has <span className="bold">sparked my love for travel</span> again. It <span className="bold">feels like I’m a local</span> with all the hidden gems I’m able to discover."</div>
      </div>
      <div className="vertical-spacing-medium" style={{ width: '50%', textAlign: 'right' }}>
        <div className="primary-header3 blue">Tailored to the modern traveler.</div>
        <div className="body2 blue vertical-spacing-medium">For the ones that like to mix <span className="bold">adventure with luxury.</span></div>
        <div className="body2 blue">The ones that love <span className="bold">street food and Michelin star.</span></div>
        <div className="body2 blue"><span className="bold">We get you.</span></div>
        <div onClick={() => handleCTA()} className=" vertical-spacing-large" style={{ display: 'inline-block', cursor: 'pointer' }}>
          <div className="v2-button-text secondary-header3 blue">{cta}</div>
          <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'left' }} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ width: '50%', textAlign: 'left', marginTop: -24 }}>
            <img style={{ height: 380, width: '100%', objectFit: 'cover' }} src={require('../images/mel.jpg')} />
            <div className="primary-header6 blue">Melissa.</div>
            <div className="body4 blue vertical-spacing-xsmall horizontal-spacing-medium" style={{ width: '80%' }}>"I love all the TikToks and videos that are included because I can quickly see which are the <span className="bold">right things for my style of travel."</span></div>
          </div>
          <div style={{ width: '40%', textAlign: 'left', marginTop: 96 }}>
            <img style={{ height: 300, width: '100%', objectFit: 'cover' }} src={require('../images/avi.jpg')} />
            <div className="primary-header6 blue">Avi.</div>
            <div className="body4 blue vertical-spacing-xsmall horizontal-spacing-medium" style={{ width: '80%' }}>"I can’t imagine booking another trip without Reverie - they understand <span className="bold">what young travelers are looking for</span> perfectly."</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const WhySection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const {
    handleCTA,
    cta
  } = props;

  return (
    <div style={{ paddingTop: 72, paddingBottom: 72, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: isMobile ? '85%' : '100%', alignSelf: 'center' }}>
      <div style={{ marginLeft: isMobile ? 0 : 156 }} className="primary-header3 blue">Meet the new way to book travel.</div>
      <div style={{ marginTop: 48, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'space-between', width: '100%' }}>
        <div style={{ marginTop: isMobile ? 24 : 0, marginLeft: isMobile ? 0 : 156, width: isMobile ? '100%' : '40%' }}>
          <div style={{ fontSize: 48, lineHeight: '1em' }} className="body1 blue">01</div>
          <div className="primary-header5 blue vertical-spacing-small">The most unique spots in travel.</div>
          <div style={{ width: isMobile ? '100%' : '80%' }} className="body2 blue vertical-spacing-medium">We curate the spots that are <span className="bold">truly unique & extraordinary</span> so that you can skip the research & go <span className="bold">straight to relaxation.</span></div>
          <div onClick={() => handleCTA()} className=" vertical-spacing-xlarge" style={{ display: 'inline-block', cursor: 'pointer' }}>
            <div className="v2-button-text secondary-header3 blue">{cta}</div>
            <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'right' }} />
          </div>
        </div>
        <img style={{ width: isMobile ? '100%' : '50%', height: isMobile ? 'auto' : '33vw' }} src={require('../images/collage1.png')} />
      </div>
      <div style={{ marginTop: isMobile ? 84 : 124, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row-reverse', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'space-between', width: '100%' }}>
        <div style={{ marginTop: isMobile ? 24 : 0, width: isMobile ? '100%' : '40%' }}>
          <div style={{ fontSize: 48, lineHeight: '1em' }} className="body1 blue">02</div>
          <div className="primary-header5 blue vertical-spacing-small">Trusted recommendations from real travelers.</div>
          <div style={{ width: isMobile ? '100%' : '80%' }} className="body2 blue vertical-spacing-medium"><span className="bold">Feel confident</span> about where you're going - see and hear from <span className="bold">travelers that have actually been there.</span></div>
          <div onClick={() => handleCTA()} className=" vertical-spacing-xlarge" style={{ display: 'inline-block', cursor: 'pointer' }}>
            <div className="v2-button-text secondary-header3 blue">{cta}</div>
            <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'right' }} />
          </div>
        </div>
        <img style={{ width: isMobile ? '100%' : '50%', height: isMobile ? 'auto' : '33vw' }} src={require('../images/collage2.png')} />
      </div>
      <div style={{ marginTop: isMobile ? 84 : 124, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ marginTop: isMobile ? 24 : 0, marginLeft: isMobile ? 0 : 156, width: isMobile ? '100%' : '40%' }}>
          <div style={{ fontSize: 48, lineHeight: '1em' }} className="body1 blue">03</div>
          <div className="primary-header5 blue vertical-spacing-small">Insider knowledge - without the research.</div>
          <div style={{ width: isMobile ? '100%' : '80%' }} className="body2 blue vertical-spacing-medium"><span className="bold">Discover tips</span> that'll make you <span className="bold">feel like a travel expert</span> - from how to get a more local experience to the best times to avoid the crowds, <span className="bold">we've researched it all.</span></div>
          <div onClick={() => handleCTA()} className=" vertical-spacing-xlarge" style={{ display: 'inline-block', cursor: 'pointer' }}>
            <div className="v2-button-text secondary-header3 blue">{cta}</div>
            <div className="v2-button-line blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '40%', float: 'right' }} />
          </div>
        </div>
        <img style={{ width: isMobile ? '100%' : '50%', height: isMobile ? 'auto' : '33vw' }} src={require('../images/collage3.png')} />
      </div>
    </div>
  );
}

const ExploreSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div style={{ paddingTop: 72, paddingBottom: 72, display: 'flex', flexDirection: 'column', alignItems: 'center', width: isMobile ? '80%' : '70%', alignSelf: 'center' }}>
      <div className="primary-header3 blue">Explore our destinations.</div>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? 'flex-start' : 'space-between'}}>
        {Destinations.map((destination, i) =>
          <div key={i} style={{ marginBottom: isMobile ? 36 : 0, cursor: 'pointer', width: isMobile ? '100%' : '30%', border: '1px solid #000814', padding: '4px 12px 6px 12px', boxSizing: 'border-box' }}>
            <div style={{ position: 'relative', zIndex: 2 }} className="primary-header5">{`${destination.name}.`}</div>
            <img style={{ zIndex: 1, marginTop: -18, height: 400, width: '100%', objectFit: 'cover' }} src={destination.image} />
          </div>
        )}
      </div>
      <div style={{ marginTop: isMobile ? 0 : 36, width: isMobile ? '100%' : '60%', textAlign: 'center' }} className="body2 blue">We’re adding new destinations every week. Don’t see a destination you need? <span className="bold" style={{ textDecoration: 'underline' }}>Request a destination</span> and we’ll add it within a week.</div>
    </div>
  );
}

export default LandingPageV2;
