import React, {useState, useEffect} from 'react';
import '../App.css';
import {Collapse} from 'react-collapse';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import {useMediaQuery} from 'react-responsive';
import Calendar from 'react-calendar';
import moment from 'moment';

function SearchModal(props) {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [selectedDestination, setSelectedDestination] = useState('Thailand');
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  const [numPeople, setNumPeople] = useState(2);

  const [destinationExpanded, setDestinationExpanded] = useState(true);
  const [datesExpanded, setDatesExpanded] = useState(false);
  const [numPeopleExpanded, setNumPeopleExpanded] = useState(false);

  const Destinations = [
    {
      name: 'Thailand',
      cover_image: require('../images/thailand_cover.jpg')
    }, {
      name: 'Japan',
      cover_image: require('../images/japan_cover.jpg')
    }, {
      name: 'Indonesia',
      cover_image: require('../images/indonesia_cover.jpg')
    }
  ]

  return (
    <div className="offwhite-fill" style={{ textAlign: 'left', borderRadius: isMobile ? 5 : 10, padding: 24, height: isMobile ? '80vh' : '85vh', border: '1px solid #1a1a1a' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="primary-header5 blue">Let's find your dream trip.</div>
        <div onClick={() => props.setSearchModalVisible(false)} className="lightergray-fill" style={{ transition: 'all 1s ease', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, borderRadius: 30, border: '1px solid lightgray' }}>
          <IconContext.Provider value={{
              size: 24,
              color: '#000814',
            }}>
            {Icons.x}
          </IconContext.Provider>
        </div>
      </div>
      <div className="vertical-spacing-medium lightergray-fill" style={{ borderRadius: 10, padding: '12px 24px 12px 24px' }}>
        <div onClick={() => {
          setDatesExpanded(false);
          setDestinationExpanded(!destinationExpanded);
          setNumPeopleExpanded(false);
        }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="secondary-header3 bold blue">Where</div>
          <div className="body2 blue">{selectedDestination}</div>
        </div>
        <Collapse isOpened={destinationExpanded}>
          <div style={{ width: isMobile ? '100%' : '30%' }}>
            {Destinations.map((destination, i) =>
              <div onClick={() => {
                setDestinationExpanded(false);
                setSelectedDestination(destination.name);
              }} className="vertical-spacing-small" style={{ cursor: 'pointer', width: '100%', height: 60, position: 'relative', borderRadius: 3, overflow: 'hidden' }}>
                <img src={destination.cover_image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                <div style={{ width: '100%', height: '100%', position: 'absolute', backgroundColor: 'black', opacity: 0.5, top: 0, right: 0, bottom: 0, left: 0 }} />
                <div className="primary-header6 white" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', WebkitTextStrokeWidth: '0em', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }}>{destination.name}</div>
              </div>
            )}
          </div>
        </Collapse>
      </div>
      <div className="vertical-spacing-small lightergray-fill" style={{ borderRadius: 10, padding: '12px 24px 12px 24px' }}>
        <div onClick={() => {
          setDestinationExpanded(false);
          setNumPeopleExpanded(false);
          setDatesExpanded(!datesExpanded);
        }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="secondary-header3 bold blue">When</div>
          <div className="body2 blue">{moment(selectedDates[0]).format('MMM D') + ' - ' + moment(selectedDates[1]).format('MMM D')}</div>
        </div>
        <Collapse isOpened={datesExpanded}>
          <div className="vertical-spacing-small">
            <Calendar
              className="calendar"
              onChange={setSelectedDates}
              value={selectedDates}
              showDoubleView={false}
              selectRange={true}
              minDate={new Date()}
              minDetail="month"
              formatShortWeekday={(locale, date) => (moment(date).format('ddd'))}
              goToRangeStartOnSelect={false}
              prevLabel={
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.AiOutlineLeft}
                </IconContext.Provider>
              }
              nextLabel={
                <IconContext.Provider value={{
                    size: 16,
                    color: '#000814',
                  }}>
                  {Icons.AiOutlineRight}
                </IconContext.Provider>
              }
            />
          </div>
        </Collapse>
      </div>
      <div className="vertical-spacing-small lightergray-fill" style={{ borderRadius: 10, padding: '12px 24px 12px 24px' }}>
        <div onClick={() => {
          setDestinationExpanded(false);
          setDatesExpanded(false);
          setNumPeopleExpanded(!numPeopleExpanded);
        }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="secondary-header3 bold blue">Who</div>
          <div className="body2 blue">{`${numPeople} travelers`}</div>
        </div>
        <Collapse isOpened={numPeopleExpanded}>
          <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className="body2 bold blue">Adults</div>
            <div className="horizontal-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div onClick={() => setNumPeople(Math.max(numPeople - 1, 1))} className="blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 30, border: '1px solid #1A1A1A' }}>
                <IconContext.Provider value={{
                    size: 16,
                    color: 'white',
                  }}>
                  {Icons.AiOutlineMinus}
                </IconContext.Provider>
              </div>
              <div className="horizontal-spacing-small body2 blue">{numPeople}</div>
              <div onClick={() => setNumPeople(numPeople + 1)} className="horizontal-spacing-small blue-fill" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 30, border: '1px solid #1A1A1A' }}>
                <IconContext.Provider value={{
                    size: 16,
                    color: 'white',
                  }}>
                  {Icons.AiOutlinePlus}
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div onClick={() => props.search({
        destination: selectedDestination,
        dates: selectedDates,
        numPeople: numPeople,
      })} className="blue-fill" style={{ borderRadius: 5, position: 'absolute', bottom: 24, left: 24, right: 24, cursor: 'pointer', height: 42, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="body2 bold white">Search</div>
      </div>
    </div>
  );
}

export default SearchModal;
