import React, {useEffect} from 'react';
import '../App.css';
import * as amplitude from '@amplitude/analytics-browser';

function PrivacyPolicy() {

  useEffect(() => {
    amplitude.track('view_about_page');
  }, []);

  return (
    <div style={{ padding: 96 }}>
    <div className="primary-header3">Reverie Travel, Inc. Privacy Policy</div>
    <div className="body3 vertical-spacing-small">Your privacy is important to us. It is Reverie Travel, Inc.&#39;s policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, <span className="bold">https://travelreverie.com</span>, and other sites we own and operate.</div>
    <div className="body3 vertical-spacing-small">This policy is effective as of 25 January 2023 and was last updated on 25 January 2023.</div>
    <div className="primary-header4 vertical-spacing-large">Information We Collect</div>
    <div className="body3 vertical-spacing-small">Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions, and any information automatically sent by your devices in the course of accessing our products and services.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Log Data</div>
    <div className="body3 vertical-spacing-small">When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, other details about your visit, and technical details that occur in conjunction with any errors you may encounter.
    </div>
    <div className="body3 vertical-spacing-small">Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Personal Information</div>
    <div className="body3 vertical-spacing-small">We may ask for personal information which may include one or more of the following:
    </div>
      <div className="body3 vertical-spacing-small">• Name</div>
      <div className="body3 vertical-spacing-small">• Email</div>
    <div className="body2 bold gray vertical-spacing-large">Legitimate Reasons for Processing Your Personal Information</div>
    <div className="body3 vertical-spacing-small">We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Collection and Use of Information</div>
    <div className="body3 vertical-spacing-small">We may collect personal information from you when you do any of the following on our website:
    </div>
      <div className="body3 vertical-spacing-small">• Enter any of our competitions, contests, sweepstakes, and surveys</div>
      <div className="body3 vertical-spacing-small">• Sign up to receive updates from us via email or social media channels</div>
      <div className="body3 vertical-spacing-small">• Use a mobile device or web browser to access our content</div>
      <div className="body3 vertical-spacing-small">• Contact us via email, social media, or on any similar technologies</div>
      <div className="body3 vertical-spacing-small">• When you mention us on social media</div>
    <div className="body3 vertical-spacing-small vertical-spacing-large">We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:</div>
      <div className="body3 vertical-spacing-small">• to enable you to customise or personalise your experience of our website</div>
      <div className="body3 vertical-spacing-small">• to contact and communicate with you</div>
      <div className="body3 vertical-spacing-small">• for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you</div>
      <div className="body3 vertical-spacing-small">• to enable you to access and use our website, associated applications, and associated social media platforms</div>
      <div className="body3 vertical-spacing-small">• for internal record keeping and administrative purposes</div>
      <div className="body3 vertical-spacing-small">• to run competitions, sweepstakes, and/or offer additional benefits to you</div>
    <div className="body3 vertical-spacing-small">Please be aware that we may combine information we collect about you with general information or research data we receive from other trusted sources.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Security of Your Personal Information</div>
    <div className="body3 vertical-spacing-small">When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.
    </div>
    <div className="body3 vertical-spacing-small">Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security. We will comply with laws applicable to us in respect of any data breach.
    </div>
    <div className="body3 vertical-spacing-small">You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services.
    </div>
    <div className="body2 bold gray vertical-spacing-large">How Long We Keep Your Personal Information</div>
    <div className="body3 vertical-spacing-small">We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. If your personal information is no longer required, we will delete it or make it anonymous by removing all details that identify you.
    </div>
    <div className="body3 vertical-spacing-small">However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Children’s Privacy</div>
    <div className="body3 vertical-spacing-small">We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.
    </div>
    <div className="body2 bold gray vertical-spacing-large">International Transfers of Personal Information</div>
    <div className="body3 vertical-spacing-small">The personal information we collect is stored and/or processed where we or our partners, affiliates, and third-party providers maintain facilities. Please be aware that the locations to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Your Rights and Controlling Your Personal Information</div>
    <div className="body3 vertical-spacing-small">You always retain the right to withhold personal information from us, with the understanding that your experience of our website may be affected. We will not discriminate against you for exercising any of your rights over your personal information. If you do provide us with personal information you understand that we will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to request details of any personal information we hold about you.
    </div>
    <div className="body3 vertical-spacing-small">If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.
    </div>
    <div className="body3 vertical-spacing-small">If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time. We will provide you with the ability to unsubscribe from our email-database or opt out of communications. Please be aware we may need to request specific information from you to help us confirm your identity.
    </div>
    <div className="body3 vertical-spacing-small">If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.
    </div>
    <div className="body3 vertical-spacing-small">If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Limits of Our Policy</div>
    <div className="body3 vertical-spacing-small">Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Changes to This Policy</div>
    <div className="body3 vertical-spacing-small">At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.
    </div>
    <div className="body3 vertical-spacing-small">If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.
    </div>
    <div className="body2 bold gray vertical-spacing-large">Contact Us</div>
    <div className="body3 vertical-spacing-small">For any questions or concerns regarding your privacy, you may contact us using the following details:
    </div>
    <div className="body3 vertical-spacing-small">Eric Singh</div>
    <div className="body3 vertical-spacing-small">eric@travelreverie.com</div>
  </div>);
}

export default PrivacyPolicy;
