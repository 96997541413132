import React, {useState, useEffect} from 'react';
import '../App.css';
import {Collapse} from 'react-collapse';
import * as amplitude from '@amplitude/analytics-browser';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import {useMediaQuery} from 'react-responsive';
import Calendar from 'react-calendar';
import moment from 'moment';
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {TailSpin} from 'react-loader-spinner';

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

function ManageAccountScreen(props) {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const [user, setUser] = useState(null);
  const [portalLoading, setPortalLoading] = useState(false);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const firebaseFunctions = getFunctions();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  return (
    <div className="white-fill" style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start', width: isMobile ? '85%' : '90%', alignSelf: 'center', paddingTop: isMobile ? 48 : 96, paddingBottom: isMobile ? 48 : 96 }}>
        <div style={{ marginLeft: isMobile ? 0 : 48, zIndex: 2, width: isMobile ? '100%' : '50%' }}>
          <div className="vertical-spacing-large primary-header2 blue">Your membership.</div>
          <div className="vertical-spacing-large secondary-header3 bold blue">Name</div>
          <div className="vertical-spacing-xsmall body3 blue">{user !== null ? user.name : 'Loading...'}</div>
          <div className="vertical-spacing-large secondary-header3 bold blue">Email</div>
          <div className="vertical-spacing-xsmall body3 blue">{user !== null ? user.email : 'Loading...'}</div>
          <div className="vertical-spacing-large secondary-header3 bold blue">Membership</div>
          <div className="vertical-spacing-xsmall body3 blue">{user !== null ? (user.stripe_subscription_status === 'active' ? `Current membership plan: ${user.stripe_subscription_amount / 100} / ${user.stripe_subscription_interval}` : 'No active subscription.') : 'Loading...'}</div>
          {user !== null &&
            <div onClick={async () => {
              if (portalLoading) {
                return;
              }
              setPortalLoading(true);
              const createStripePortal = httpsCallable(firebaseFunctions, 'createstripeportal');
              const stripe_portal = await createStripePortal({
                stripe_customer_id: user.stripe_customer_id,
              });
              window.open(stripe_portal.data.session_url, "_self");
              setPortalLoading(false);
            }} style={{ cursor: 'pointer' }} className="vertical-spacing-medium body3 bold blue">
            {portalLoading ?
              <TailSpin
                height="15"
                width="15"
                color='#000814'
                visible={true}
              />
              :
              'Manage my membership'
            }
            </div>
          }
          <div style={{ height: 1, width: '20%' }} className="vertical-spacing-large blue-fill" />
          <div onClick={() => {
            signOut(auth).then(() => {
              window.open(window.location.origin, '_self');
            }).catch((error) => {
              // An error happened.
              console.log(error);
            });
          }} style={{ cursor: 'pointer' }} className="vertical-spacing-large body2 bold blue">Log out</div>
        </div>
        <img style={{ marginTop: isMobile ? 48 : 0, marginLeft: isMobile ? 0 : -96, height: isMobile ? 'auto' : '40vw', width: isMobile ? '100%' : '50%', objectFit: 'contain' }} src={require('../images/hero.png')} />
      </div>
    </div>
  );
}

export default ManageAccountScreen;
